import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "../../Components";
import { getPromptResponse, getWelcomeMessage } from "../../Redux/actions";
import { BASE_URL, socket } from "../../config";
import { Col, Row } from "react-bootstrap";
import { IMAGES } from "../../Assets";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
import axios from "axios";
import ChatCard from "../StartChat/StartChatCard";

export default function ExternalAvatar() {
  const [file, setFile] = useState();
  const [previewImg, setPreviewImg] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [startListening, setStartListening] = useState(false);
  const [gender, setGender] = useState(null);

  const inActiveTimer = useRef(null);
  const { state } = useLocation();
  const [chatArray, setChatArray] = useState([]);

  const playerRef = useRef();
  const USER_ID = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat);

  function startInActiveTimer() {
    inActiveTimer.current = setTimeout(() => {
      clearTimeout(inActiveTimer.current);
      // }, 10000);
    }, 600000);
  }

  function resetInActiveTimer() {
    clearTimeout(inActiveTimer.current);
    startInActiveTimer();
  }

  const sendPrompt = (messageValue) => {
    resetInActiveTimer();

    const prompt = {
      id: Date.now() + Math.random(),
      type: `${file ? "imagePrompt" : "prompt"}`,
      message: messageValue,
      file: previewImg,
    };
    setChatArray([...chatArray, prompt]);
    setPreviewImg(null);
    dispatch(
      getPromptResponse(messageValue, USER_ID, gender === "male" ? 0 : 1, file)
    );
  };

  const scrollFunction = () => {
    const scroller = document.querySelector("#chatBox");
    scroller?.scroll(0, 10000);
  };

  useEffect(() => {
    // if (state?.isGender === undefined) {
    //   navigate(ROUTER_PATH.HOME_PAGE);
    // }
    scrollFunction();
  }, [chatArray]);

  useEffect(() => {
    //
    // if (state?.isGender !== null && unityLoaded) {
    if (gender !== null) {
      dispatch(getWelcomeMessage({ id: gender === "male" ? 0 : 1 }));
    }
    // }, [dispatch, state, unityLoaded]);
  }, [dispatch, state]);

  useEffect(() => {
    if (chat?.PROMPT_RESPONSE?.generated_text) {
      let reply = {
        id: Date.now() + Math.random(),
        type: "reply",
        reply: chat?.PROMPT_RESPONSE?.generated_text,
      };
      setChatArray([...chatArray, reply]);
      // resetInActiveTimer();
      setFile(null);
    }
    // eslint-disable-next-line
  }, [chat?.PROMPT_RESPONSE?.generated_text]);

  useEffect(() => {
    if (socket) {
      socket?.on("connect", () => {
        console.log("socket connected", socket?.connected);
        socket?.emit("join", { email: "chat-avatar@innotech.com" });
      });
      socket.on("message", (data) => {
        console.log("data", data);
        setVideoPlaying(data?.isPlaying);
        setGender(data?.gender);

        // const url = new URL(window.location);
        // url.searchParams.set('gender', data?.gender);
        // navigate(url.pathname + url.search);
      });
    }
  }, [socket]);

  let mediaRecorder = null;
  let recordingStart = false;
  let audioStream = null;
  const timerRef = useRef(null);
  const MIN_DECIBELS = -40;

  const stopRecordingVoice = () => {
    mediaRecorder?.stop();
    audioStream.getTracks().forEach((track) => track.stop());
    recordingStart = false;
    setStartListening(false);
  };
  const startTimeCount = () => {
    timerRef.current = setTimeout(() => {
      clearTimeout(timerRef.current);
      stopRecordingVoice();
    }, 5000);
  };
  function handleResetTimer() {
    clearTimeout(timerRef.current);
    startTimeCount();
  }

  const startVoiceRecording = async () => {
    resetInActiveTimer();
    audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    mediaRecorder = new MediaRecorder(audioStream);
    mediaRecorder.start();

    recordingStart = true;
    startTimeCount();
    const audioChunks = [];
    mediaRecorder.addEventListener("dataavailable", (event) => {
      audioChunks.push(event.data);

      resetInActiveTimer();
    });

    const audioContext = new AudioContext();
    const audioStreamSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    analyser.minDecibels = MIN_DECIBELS;
    audioStreamSource.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const domainData = new Uint8Array(bufferLength);

    let soundDetected = false;

    const detectSound = () => {
      if (!recordingStart) {
        // console.log("not recording");
        return;
      }

      analyser.getByteFrequencyData(domainData);

      for (let i = 0; i < bufferLength; i++) {
        const value = domainData[i];

        if (domainData[i] > 0) {
          // console.log("sound detected");
          soundDetected = true;
          handleResetTimer();
        }
      }

      window.requestAnimationFrame(detectSound);
    };

    window.requestAnimationFrame(detectSound);

    mediaRecorder.addEventListener("stop", async () => {
      if (soundDetected) {
        const audioBlob = new Blob(audioChunks, {
          type: "audio/wav",
        });
        sendAudioToAPI(audioBlob);
      }
      // console.log("soundDetected", soundDetected);
      resetInActiveTimer();
    });
  };

  // Add the window event listener for beforeunload
  useEffect(() => {
    // Add the window event listener for beforeunload
    const handleBeforeUnload = () => {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    // checkAndRequestMicrophonePermission();
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Stop speech synthesis when the component unmounts
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };
  }, []);

  const sendAudioToAPI = async (audioData) => {
    try {
      const formData = new FormData();
      formData.append("audio_file", audioData, "recorded-audio.wav");
      const response = await axios.post(
        BASE_URL + "/speech_to_text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      sendPrompt(response.data.response);
    } catch (error) {
      console.error("Error sending audio to API:", error);
    }
  };

  useEffect(() => {
    if (startListening) {
      var resetTimer = setTimeout(() => {
        resetInActiveTimer();
      }, 1000);
      startVoiceRecording();
    }
    return () => {
      clearTimeout(resetTimer);
    };
  }, [startListening]);

  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (audioRef?.current?.paused) {
      // audioRef.current.play();
    } else {
      // audioRef.current.pause();
      resetInActiveTimer();
    }
  };

  useEffect(() => {
    let handlePlayPauseTimer = setTimeout(() => {
      handlePlayPause();
    }, 10000);
    return () => {
      clearTimeout(handlePlayPauseTimer);
    };
  }, []);

  const handlePause = () => {
    // Check if the player reference exists
    if (playerRef?.current) {
      // Set the current time of the video to 0 seconds
      playerRef?.current?.seekTo(0);
    }
  };

  return (
    <div style={{ zIndex: gender ? "-1" : "auto" }}>
      {!gender ? (
        <ChatCard hideConsultationBtn="false" />
      ) : (
        <Box
          // width={width < 992 ? "100%" : "30rem"}
          // minWidth="30rem"
          width="100%"
          height="100%"
          // borderRadius="1.375rem"
          background="transparent"
          // shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
          // backdropFilter="blur(15px)"
          className="chat-history-box chat-box-a"
          // className="mb-5 chat-history-box me-3 py-3 d-sm-none d-lg-block"
        >
          {/* <div>
            <Typography as="h6" align="center">
              {gender === "male" ? "Dr. William" : "Dr. Elizabeth"}
            </Typography>
          </div> */}
          <div className="video-container text-center pb-2 position-relative" style={{width: '98vw'}}>
            {gender === "male" ? (
              <ReactPlayer
                url={IMAGES.MALE_STANDING_DOCTOR}
                ref={playerRef}
                muted
                playing={videoPlaying}
                loop={videoPlaying}
                width={"100%"}
                height={"100%"}
                onPause={handlePause}
              />
            ) : (
              gender === "female" && (
                <ReactPlayer
                  url={IMAGES.FEMALE_STANDING_DOCTOR}
                  ref={playerRef}
                  muted
                  playing={videoPlaying}
                  loop={videoPlaying}
                  width={"100%"}
                  height={"100%"}
                  onPause={handlePause}
                />
              )
            )}
          </div>
          
        </Box>
      )}
    </div>
  );
}
