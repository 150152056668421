import { Outlet, useLocation } from "react-router-dom";
import LayoutNavbar from "./Navbar/LayoutNavbar";
import bgVideo from "../Assets/video/innotech_bg.mp4";
import { ROUTER_PATH, socket } from "../config";
import { useEffect, useState } from "react";

export default function Layout() {
  const [gender, setGender] = useState(null);
  const location = useLocation();
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;

  useEffect(() => {
    if (socket) {
      socket?.on("connect", () => {
        socket?.emit("join", { email: "chat-avatar@innotech.com" });
      });
      socket.on("message", (data) => {
        setGender(data?.gender);
      });
    }
  }, [socket]);

  return (
    <div className={location.pathname === ROUTER_PATH.EXTERNAL_AVATAR && gender ? 'w-100 bg-black' : location.pathname === ROUTER_PATH.EXTERNAL_AVATAR && !gender ? '' : 'w-100'}>
      {isPortrait && location.pathname === ROUTER_PATH.PATIENT_DIAGNOSIS && !gender && (
        <video src={bgVideo} autoPlay loop muted className="bg-video" />
      )}
      {location.pathname !== ROUTER_PATH.PATIENT_DIAGNOSIS  && !gender && (
        <video src={bgVideo} autoPlay loop muted className="bg-video" />
      )}
      <div
        className={`${
          location.pathname !== ROUTER_PATH.EXTERNAL_AVATAR
            ? "main-container"
            : ""
        } ${
          location.pathname === ROUTER_PATH.EXTERNAL_AVATAR && gender
            ? "d__orient-portrait"
            : location.pathname === ROUTER_PATH.EXTERNAL_AVATAR && !gender ? "d__orient-portrait d__orient-more"
            : ""
        }`}
        style={{
          background: location.pathname === ROUTER_PATH.SIGN_IN ? "white" : "none",
        }}
      >
        {/* <video src={bgVideo} autoPlay loop muted className="bg-video" /> */}
        <LayoutNavbar gender={gender} />
        <Outlet />
      </div>
    </div>
  );
}
