import { Container, Navbar } from "react-bootstrap";
import { IMAGES } from "../../Assets";
import { useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function LayoutNavbar({ gender }) {
  const location = useLocation();

  return (
    <Navbar className={`bg-none ${gender ? "position-relative" : ""}`}>
      <Container
        className={`m-0 ${
          gender && location.pathname === ROUTER_PATH.EXTERNAL_AVATAR
            ? "icon-absolute"
            : ""
        }`}
      >
        <Navbar.Brand href="#home">
          <img
            src={IMAGES.INNOTECH_LOGO}
            width="250px"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
